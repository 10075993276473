import { Col } from "antd";

const Styles = {
  dataContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  textContent: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContent: {
    marginTop: "24px",
  },
};

export function GridCardImage(props) {
  const title = props.title;
  const description = props.description;
  const image = props.image;
  const navigationComponent = props.navComponent;

  return (
    <div>
      <Col>
        <div class="flex flex-col bg-white border border-gray-200 rounded-lg md:flex-row md:max-w-md dark:border-gray-700 dark:bg-gray-800">
          <img
            class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
            src={image}
            alt=""
          />
          <div className="p-4" style={Styles.dataContent}>
            <div
              class="justify-between leading-normal"
              style={Styles.textContent}
            >
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {title}
              </h5>
              <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                {description}
              </p>
            </div>
            <div style={Styles.buttonContent}>{navigationComponent}</div>
          </div>
        </div>
      </Col>
    </div>
  );
}
