import personImage from "../../assets/person.png";

const Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "36px",
    marginBottom: "12px",
  },
  image: {
    width: "50%",
    borderRadius: "50%",
    overflow: "hidden",
    marginBottom: "10px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  textTitle: {
    width: "80%",
    marginTop: "8px",
    whiteSpace: "wrap",
    textAlign: "center",
    marginBottom: "4px",
  },
  textDescription: {
    width: "80%",
    whiteSpace: "wrap",
    marginBottom: "12px",
    textAlign: "center",
  },
};

const DescriptionHeader = (props) => {
  const isDrawerOpen = props.isDrawerOpen;

  if (isDrawerOpen) {
    return (
      <div style={Styles.textContainer}>
        <h4 className="global-font" style={Styles.textTitle}>
          Gabriel Brasileiro
        </h4>
        <div className="global-font" style={Styles.textDescription}>
          Staff Software Engineer
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

const PersonHeader = (props) => {
  const isDrawerOpen = props.isDrawerOpen;

  return (
    <div style={Styles.container}>
      <img style={Styles.image} src={personImage} alt="" />
      <DescriptionHeader isDrawerOpen={isDrawerOpen} />
    </div>
  );
};

export default PersonHeader;
