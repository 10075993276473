import type { CustomFlowbiteTheme } from "flowbite-react";
import { Col } from "antd";
import { Card } from "flowbite-react";
import { openNewTab } from "../ext/WindowExtensions";

const Styles = {
  cardContainer: {
    minHeight: "180px",
  },
};

const cardTheme: CustomFlowbiteTheme = {
  root: {
    base: "flex rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800",
  },
};

export default function GridCardButton(props) {
  return (
    <div>
      <Col>
        <Card
          href="#"
          className="max-w-sm"
          theme={cardTheme}
          style={Styles.cardContainer}
          imgSrc={props.image}
          onClick={() => {
            openNewTab(props.link);
          }}
          horizontal
        >
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {props.title}
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400"></p>
        </Card>
      </Col>
    </div>
  );
}
