import DeeplinkContext from "../deeplink/DeeplinkContext";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

const DeepvokePage = () => {
  return (
    <div style={Styles.container}>
      <DeeplinkContext />
    </div>
  );
};

export default DeepvokePage;
