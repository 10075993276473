import PresentationHeaderSocial from "./PresentationHeaderSocial";

const Styles = {
  parent: {
    display: "block",
  },
  backgroundImage: {
    display: "inline-block",
    width: "100%",
  },
  userImage: {
    position: "relative",
    width: "25%",
    borderRadius: "50%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-15%",
    border: "5px solid #FFFFFF",
  },
  title: {
    textAlign: "center",
    marginTop: "12px",
    fontWeight: 500,
    fontSize: "24px",
  },
  description: {
    marginTop: "24px",
    marginLeft: "48px",
    marginRight: "48px",
    textAlign: "center",
  },
};

export default function PersonHeaderPresentation() {
  return (
    <div style={Styles.parent}>
      <img
        style={Styles.backgroundImage}
        src={require("../../assets/presentation_background.jpeg")}
        alt=""
      />
      <img
        style={Styles.userImage}
        src={require("../../assets/person.png")}
        alt=""
      />
      <p className="global-font" style={Styles.title}>
        Gabriel Brasileiro
      </p>
      <p className="global-font" style={Styles.description}>
        Dono do canal e site Universo do Android. A pricípio criado para ajudar
        aos usuários a desfrutarem do máximo da performance do seu dispositivo
        Android.
        <br />
        <br />
        Atualmente trabalhando com a parte de desenvolvimento de aplicativos
        nativos para Android e iOS (Kotlin, Java e Swift), e na ministração de
        palestras e workshops.
        <br />
        <br />
        Conhecimento em Web Design devido ao curso técnico que completei durante
        os 3 anos de ensino médio. Várias certificações em Inglês e nas áreas de
        programação para Mobile.
        <br />
        <br />
        Atualmente apaixonado por investimentos e estudando cada vez mais sobre
        produtos e suas aplicações.
      </p>
      <div style={Styles.buttonsCotainer}>
        <PresentationHeaderSocial />
      </div>
    </div>
  );
}
