import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DeeplinkKeyParams from "./DeeplinkKeyParams";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeeplinkManuallyQueryDialog = (props) => {
  const openDialog = props.openDialog;
  const handleClose = props.handleClose;
  const currentQueryParameters = props.currentQueryParameters;
  const handleQueryParameters = props.handleQueryParameters;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogContent>
          <DeeplinkKeyParams
            currentQueryParameters={currentQueryParameters}
            handleQueryParameters={handleQueryParameters}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DeeplinkManuallyQueryDialog;
