import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PersonHeader from "./PersonHeader";

const DIV = "div";
const Style = {
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  drawerController: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "flex-end",
  },
  personDrawer: {
    width: "100%",
  },
};

const DrawerHeader = styled(DIV)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const PersonDrawerHeader = (props) => {
  const handleCloseDrawer = props.handleCloseDrawer;
  const isDrawerOpen = props.isDrawerOpen;

  return (
    <div>
      <DrawerHeader>
        <div style={Style.drawerContainer}>
          <div style={Style.drawerController}>
            <IconButton onClick={handleCloseDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <div style={Style.personDrawer}>
            <PersonHeader isDrawerOpen={isDrawerOpen} />
          </div>
        </div>
      </DrawerHeader>
    </div>
  );
};

export default PersonDrawerHeader;
