import "./App.css";
import RouterContainer from "./components/router/RouterContainer";
import { BrowserRouter } from "react-router-dom";

function AppRouterContainer() {
  return (
    <BrowserRouter>
      <RouterContainer />
    </BrowserRouter>
  );
}

function App() {
  return <AppRouterContainer />;
}

export default App;
