import * as React from "react";

import { Button, Timeline } from "flowbite-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeTitle } from "../state/titleSlicer";
import { presentationTitle } from "./TitlePages";
import PersonHeaderPresentation from "../header/PersonHeaderPresentation";
import { openNewTab } from "../ext/WindowExtensions";

const Styles = {
  container: {
    marginTop: "48px",
    marginLeft: "48px",
    marginBottom: "56px",
    marginRight: "56px",
  },
  buttonContainers: {
    display: "flex",
    flexDirection: "row",
  },
  buttonRight: {
    marginLeft: "12px",
  },
};

function TimelineImage(props) {
  return (
    <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
      <img class="rounded-full shadow-lg" src={props.image} alt="" />
    </span>
  );
}

export default function PresentationPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(presentationTitle));
  });

  return (
    <div>
      <PersonHeaderPresentation />
      <div style={Styles.container}>
        <Timeline className="global-font">
          <Timeline.Item>
            <Timeline.Content>
              <TimelineImage image={require("../../icons/porto.png")} />
              <Timeline.Time>Fevereiro de 2023 - Atual</Timeline.Time>
              <Timeline.Content>Porto</Timeline.Content>
              <Timeline.Title>Staff Software Engineer</Timeline.Title>
              <Timeline.Body>
                <p>
                  Engenheiro de Software com especialidade mobile.
                  <br />
                  <br />
                  Atuação a frente das squads (Histórico):
                  <br /> - Autenticação;
                  <br /> - Cross;
                  <br /> - Plataforma;
                  <br /> - Analytics;
                  <br /> - Engajamento.
                  <br />
                  <br /> Global:
                  <br /> - Gerenciamento da distribuição de versões;
                  <br /> - Loja de aplicativos;
                  <br /> - Negócio;
                  <br /> - CI/CD;
                  <br /> - Ofuscação.
                  <br />
                  <br /> Local:
                  <br /> Atualmente com uma atuação mais direta no time de
                  plataforma, realizando orientação global e local de estruturas
                  criadas para todo o escopo de atuação do projeto.
                  <br />
                  <br /> Atuações diretas em features:
                  <br />
                  Seguro celular, CRM, CPCP (Crédito Pessoal com Cartão Porto),
                  engenharia global, sessão, pipeline (CI/CD), Cirque Du Soleil.
                </p>
              </Timeline.Body>
              <Button
                onClick={() => {
                  openNewTab(
                    "https://play.google.com/store/apps/details?id=br.com.portoseguro.experienciacliente.mundoporto&hl=pt_BR"
                  );
                }}
                color="gray"
              >
                Conheça o SuperApp da Porto
              </Button>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <TimelineImage image={require("../../icons/porto.png")} />
            <Timeline.Content>
              <Timeline.Time>Abril de 2021 - Fevereiro de 2023</Timeline.Time>
              <Timeline.Content>Porto</Timeline.Content>
              <Timeline.Title>Specialist Software Engineer</Timeline.Title>
              <Timeline.Body>
                <p>
                  Engenheiro de Software com especialidade android.
                  <br />
                  <br />
                  Features: <br />
                  Security; Plataform; Cards; Baking; Facial Biometry; Token;
                  Baking Security; Login; Core; Support; Internal libraries.
                  <br />
                  <br />
                  Skills: <br />
                  Clean Architecture; Hexagonal; Kotlin, Design Patterns
                  (Facade, Observer, Factory, Strategy, Builder, Adapter),
                  Refatoração; CI; Jenkins; Jacoco; Detekt; Groovy;
                  Modularização; Koin; Mockk; Mockito; Coroutines; Flow; Camera;
                  Annotations; Gradle; Code Smells, Test Smells; Unit Tests;
                  Instrumented Tests; Robot Pattern.
                </p>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <TimelineImage
              image={require("../../icons/universodoandroid.jpeg")}
            />
            <Timeline.Content>
              <Timeline.Time>Janeiro de 2013 - Momento</Timeline.Time>
              <Timeline.Content>Universo do Android</Timeline.Content>
              <Timeline.Title>CEO e Dono</Timeline.Title>
              <Timeline.Body>
                <p>
                  Canal e Site criados com o objetivo de ensinar as pessoas a
                  realizarem processos diversificados nos dispositivos Android.
                  <br />
                  <br />
                  Recentemente foi iniciado um projeto no canal/site para
                  ensinar aos usuários a desenvolverem APPs para Android.
                  <br />
                  <br /> Atualmente desenvolvendo APP para Android com o intuito
                  de ajudar aos usuários a utilizarem seu Android de forma
                  simplificada Desenvolvedor do APP Udroid que atualmente se
                  encontra em versão Alpha.
                </p>
              </Timeline.Body>
              <div style={Styles.buttonContainers}>
                <Button
                  onClick={() => {
                    openNewTab("https://www.youtube.com/c/universodoandroid");
                  }}
                  color="gray"
                >
                  Canal
                </Button>
                <Button
                  style={Styles.buttonRight}
                  onClick={() => {
                    openNewTab("http://www.universodoandroid.com");
                  }}
                  color="gray"
                >
                  Site
                </Button>
              </div>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <TimelineImage image={require("../../icons/picpay.jpeg")} />
            <Timeline.Content>
              <Timeline.Time>Dezembro de 2019 - Abril de 2021</Timeline.Time>
              <Timeline.Content>PicPay</Timeline.Content>
              <Timeline.Title>Android Developer</Timeline.Title>
              <Timeline.Body>
                <p>
                  Engenheiro de Software Android.
                  <br />
                  <br /> Trabalhando com Clean Architecture, Java, Kotlin,
                  Design Patterns (Facade, Observer, Factory, Strategy...), code
                  smells, refatoração, CI, modularização, koin, RXJava2,
                  annotations, deeplinks, gradle, testes e testes
                  instrumentados, Firebase.
                  <br />
                  <br />
                  Features trabalhadas: <br />
                  PIX, Core, Business Core, Deeplinks, Presentes, Troca de
                  senha, Renovação Cadastral, Deeplink Store, Identidade,
                  Cadastro Dinâmico, Extrato, Tutorial.
                </p>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <TimelineImage image={require("../../icons/zupinnovation.jpeg")} />
            <Timeline.Content>
              <Timeline.Time>Agosto de 2019 - Novembro de 2019</Timeline.Time>
              <Timeline.Content>Zup Innovation</Timeline.Content>
              <Timeline.Title>Android Developer</Timeline.Title>
              <Timeline.Body>
                <p>
                  Densenvolvedor Android
                  <br />
                  <br />
                  Desenvolvedor Android Trabalhando com componetização, MVVM e a
                  Clean Architecture. Desenvolvendo como consultor para um
                  grande banco espanhol.
                </p>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <TimelineImage image={require("../../icons/handtalk.jpeg")} />
            <Timeline.Content>
              <Timeline.Time>Novembro de 2018 - Agosto de 2019</Timeline.Time>
              <Timeline.Content>Hand Talk</Timeline.Content>
              <Timeline.Title>Mobile Developer</Timeline.Title>
              <Timeline.Body>
                <p>
                  Aplicativos Android e IOS nativos (Java, Kotlin e Swift) OKR
                  (Objetivos e Principais Resultados) Líder. <br />
                  <br />
                  Eu tive a experiência de desenvolver com Java, Kotlin e Swift.
                  Exeperiência com MVP (Model View Presenter), aplicando o SOLID
                  para o código ter uma melhor legibilidade. Em 60% do tempo
                  trabalhei com Swift. <br />
                  <br />
                  Na gestão trabalhando com os OKRs, elaborei estratégias
                  baseadas nas estatísticas adquiridas com as métricas do
                  aplicativo.
                </p>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <TimelineImage image={require("../../icons/stant.jpeg")} />
            <Timeline.Content>
              <Timeline.Time>Março de 2018 - Novembro de 2018</Timeline.Time>
              <Timeline.Content>Stant</Timeline.Content>
              <Timeline.Title>Android Developer</Timeline.Title>
              <Timeline.Body>
                <p>
                  Desenvolvimento Nativo com Java e Kotlin.
                  <br />
                  <br />
                  Experiência com VIPER, MVP e Componentização. Em uma das
                  tarefas desenvolvi parte de uma lib para o processamento de
                  imagens.
                </p>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <TimelineImage image={require("../../icons/ifal.jpeg")} />
            <Timeline.Content>
              <Timeline.Time>Março de 2018 - Novembro de 2018</Timeline.Time>
              <Timeline.Title>Desenvolvedor de Jogos e Designer</Timeline.Title>
              <Timeline.Body>
                <p>
                  Extensionista em criação de jogos
                  <br />
                  <br />
                  Projeto de extensão realizado com o objetivo de desenvolver um
                  jogo com imagens adaptas para crianças altistas.
                  <br />
                  <br />
                  Tecnologias:
                  <br /> Lua, CoronaSDK, Android, iOS, Figma.
                </p>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
          <Timeline.Item>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Time>Setembro de 2017 - Junho de 2018</Timeline.Time>
              <Timeline.Content>BWN Aplicativos</Timeline.Content>
              <Timeline.Title>Estagiário</Timeline.Title>
              <Timeline.Body>
                <p>
                  Desenvolvedor Android e Beck-End com Java.
                  <br />
                  <br />
                  Tecnologias:
                  <br /> Atuação com PostgreSQL, SQL, JasperReports, JSF,
                  Tomcat.
                </p>
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        </Timeline>
      </div>
    </div>
  );
}
