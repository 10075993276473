import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "title",
  initialState: {
    title: "",
  },
  reducers: {
    changeTitle(state, action) {
      return { ...state, title: action.payload };
    },
  },
});

export const { changeTitle } = slice.actions;

export default slice.reducer;
