import { Button, TextField } from "@mui/material";
import { useState } from "react";

const Style = {
  principalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  insertContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px",
  },
  keyButton: {
    marginRight: "12px",
    marginLeft: "12px",
    width: "14vh",
    minWidth: "30px",
  },
  valueButton: {
    marginLeft: "12px",
    marginRight: "12px",
    width: "14vh",
    minWidth: "30px",
  },
  saveButton: {
    marginTop: "24px",
  },
  insertTitle: {
    marginTop: "12px",
    fontWeight: 400,
    fontSize: "20px",
  },
};

const DeeplinkKeyInsert = (props) => {
  const insertQueryParameter = props.insertQueryParameter;

  const [keyParameter, setKeyParameter] = useState("");
  const [valueParameter, setValueParameter] = useState("");

  const validateAndInsert = () => {
    const currentKeyParamater = keyParameter.trim();
    const currentValueParameter = valueParameter.trim();

    if (currentKeyParamater === "" || currentValueParameter === "") {
      return;
    } else {
      insertQueryParameter(currentKeyParamater, currentValueParameter);
    }
  };

  return (
    <div style={Style.principalContainer}>
      <div style={Style.insertTitle} className="global-font">
        Insert query parameter
      </div>
      <div style={Style.insertContainer}>
        <TextField
          style={Style.keyButton}
          className="global-font"
          id="filled-basic"
          label="Key"
          variant="filled"
          onChange={(event) => {
            setKeyParameter(event.target.value);
          }}
        />
        <h3>:</h3>
        <TextField
          style={Style.valueButton}
          className="global-font"
          id="filled-basic"
          label="Value"
          variant="filled"
          onChange={(event) => {
            setValueParameter(event.target.value);
          }}
        />
      </div>
      <Button
        style={Style.saveButton}
        variant="outlined"
        onClick={() => validateAndInsert()}
      >
        Insert
      </Button>
    </div>
  );
};

export default DeeplinkKeyInsert;
