import { Routes, Route, Navigate } from "react-router-dom";
import BooksPage from "../pages/BooksPage";
import PageContainer from "../principal/PageContainer";
import CalculatorPage from "../pages/CalculatorPage";
import ProgramsPage from "../pages/ProgramsPage";
import PresentationPage from "../pages/PresentationPage";
import ArticlesPage from "../pages/ArticlesPage";
import DeepvokePage from "../pages/DeepvokePage";

const RouterContainer = () => {
  return (
    <Routes>
      <Route element={<PageContainer />}>
        <Route path="/" exact element={<PresentationPage />} />
        <Route path="/livros" exact element={<BooksPage />} />
        <Route path="/programas" exact element={<ProgramsPage />} />
        <Route path="/calculadoras" exact element={<CalculatorPage />} />
        <Route path="/artigos" exact element={<ArticlesPage />} />
        <Route path="/deepvoke" exact element={<DeepvokePage />} />
        <Route path="*" exact element={<Navigate to="/" replace />} />
      </Route>
      <Route path="*" exact element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default RouterContainer;
